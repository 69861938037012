import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPhone,faExternalLinkAlt,  faHeart, faLifeRing, faChevronRight, faEye, faCheck } from "@fortawesome/free-solid-svg-icons"

export default class Page extends React.Component {
  render() {
    return (<Layout container={true} >
        <meta name="robots" content="noindex, nofollow"/>
        <div className="post-heading text-center">
            <h1>Equipment</h1>
        </div>
        <div className="post">

            <div className={"text"}>
            <h2>Miete beantragen</h2>
            <ol>
                <li>
                    <h3>Mietvertrag entwerfen</h3>
                    <p>S2 ist unsere interne Verwaltungs Software, Du kannst allerdings selbst Mietverträge entwerfen.</p>
                    <a href="https://s2.ziegenhagel.com/register" className="btn btn-primary-outline" target="_blank"><FontAwesomeIcon icon={faExternalLinkAlt}></FontAwesomeIcon> Bei S2 registrieren</a> &nbsp;
                    <a href="https://s2.ziegenhagel.com/" className="btn btn-primary-outline" target="_blank"><FontAwesomeIcon icon={faExternalLinkAlt}></FontAwesomeIcon> Bei S2 einloggen</a>
                    <p>Bitte erstelle ein Projekt und einen Mietvertrag. Dieser wird im Status "Entwurf" stehen.</p>
                </li>
                <li>
                    <h3>Mietvertrag von "Entwurf" auf "Reserviert" umstellen lassen</h3>
                    <p>Wenn Du fertig bist, klicke auf "Reservierung beantragen".</p>
                </li>
            </ol>
            <h2>Abwicklung</h2>
            <ol>
                <li>
                    <h3>Zusage & Selbstabholung</h3>
                    <p>Falls Du eine Zusage erhälst, überweise bitte die Kaution inkl. Miete an das im Mietvertrag angegebene Konto.</p>
                </li>
                <li>
                    <h3>Zusage & Versand</h3>
                    <p>Falls Du eine Zusage erhälst, wird sich jemand um den Versand kümmern. Diese Person erhält eine Aufwandsentschädigung von 20€.
                    Bitte überweise Kaution, Miete, Versandkosten und Aufwandsentschädigung an das im Mietvertrag angegebene Konto.</p>
                </li>
            </ol>
            </div>

            <br />
            <br />
            <br />
        </div>
      </Layout >
    )
  }
}


